import { useEffect, useState } from "react";
import axios from "axios";
import NoActiveSubscriptionPage from "../components/FallbackPages/NoActiveSubscriptionPage";

export const useHasSubscription = () => {

	const token: string | null = localStorage.getItem("token");
	const config = token ? {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	} : {};

	const [isLoadingSubscription, setIsLoadingSubscription] = useState<boolean>(false);
	const [subscriptionData, setSubscriptionData] = useState<any>(null);
	const [packageType, setPackageType] = useState<string>(null);

	useEffect(() => {
		setIsLoadingSubscription(true);
		subscriptionHandler();
	}, []);

	const subscriptionHandler = () => {

		const getData = async () => {
			try {
				// await axios(`http://localhost:5478/api/subscription/get-company-main-subscription`, config)
				await axios(`https://recruitment-micro.urecruits.com/api/subscription/get-company-main-subscription`, config)
					.then((res) => {
						if (!!res.data && Object.keys(res.data).length > 0) {
							setSubscriptionData(res.data);
							setPackageType(res.data.packageType ?? null);
						}
						setIsLoadingSubscription(false);
					});
			} catch (err) {
				setIsLoadingSubscription(false);
				
			}

		};
		getData().then();
	};

	const checkCompanySubscription = () => {
		if(!isLoadingSubscription && subscriptionData && subscriptionData.subId != "noSubscription"){
			return true
		}
		return false
	};

	const checkCurrentPackageType = (pacType) => {
		if (!isLoadingSubscription && packageType && pacType) {
			if(typeof pacType === 'string' && packageType === pacType){
				return true;
			}
			else if(Array.isArray(pacType) && pacType.includes(packageType)){
				return true;
			}
		} else {
			return false;
		}
	};

	const hasCompanySubscriptionElement = (element) => {
		const isCompanySubscription = checkCompanySubscription();
		if(isCompanySubscription){
			return element;
		}
		if(isLoadingSubscription){
			return <h1>Loading...</h1> 
		}  
		return <NoActiveSubscriptionPage/>
	};

	return {
		subscriptionData,
		isLoadingSubscription,
		setIsLoadingSubscription,
		checkCompanySubscription,
		hasCompanySubscriptionElement,
		packageType,
		checkCurrentPackageType
	}
};

